import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
 
import Login from './Components/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeNew from './Components/HomeNew';
import HomeNewDatesRange from './Components/HomeNewDatesRange';
import RenewHome from './Components/RenewHome';
import { AuthProvider } from './Components/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <AuthProvider>
      <Routes>
        <Route path="/" element={<Login/>}/>
        {/* <Route path='homeold' element={<Home/>}/> */}
        <Route path='/homeold' element={<ProtectedRoute><HomeNewDatesRange/></ProtectedRoute>}/>
        <Route path='/home' element={<ProtectedRoute><RenewHome/></ProtectedRoute>}/>

      </Routes>
      </AuthProvider>
      </BrowserRouter>
      {/* <Login/> */}
       {/* <Home/> */}
    </div>
  );
}

export default App;
