import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { getList, getUniqueByKey, returningValue } from "../Utils/Config";
import moment from "moment";
import { useFormValidation } from "../Validations/useFormValidations";
import { Button, Col } from "react-bootstrap";
import anatrx from "../Images/2 (2).png";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineSettings } from "react-icons/md";
import { GoBell } from "react-icons/go";

const HomeNewDatesRange = () => {
  const [values, setValues] = useState([]);
  const [filValues, setFilValues] = useState([]);
  const [types, setTypes] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedIdDetails, setSelectedIdDetails] = useState(null);

  const { data, formChange, addObject, handleDateChange } = useFormValidation({});
  const navigate = useNavigate();

  const getAllData = async () => {
    let res = await getList("GetData/2", { data: "data" });
    res = res?.map((e) => ({ ...e, ...e?.indicators }));
    setValues(res);
    setFilValues(res);
  };

  const settingTraces = () => {
    let res = values;
    if (returningValue(parseInt(data?.idNumber), ""))
      res = res?.filter((e) => e["ID Number"] === parseInt(data?.idNumber));
    if (returningValue(data?.fromDate, ""))
      res = res?.filter(
        (e) =>
          moment(e?.["Encounter date"])?.format("YYYYMMDD") >= moment(data?.fromDate)?.format("YYYYMMDD") ||
          moment(e?.["Discharge date"])?.format("YYYYMMDD") >= moment(data?.fromDate)?.format("YYYYMMDD")
      );
    if (returningValue(data?.toDate, ""))
      res = res?.filter(
        (e) =>
          moment(e?.["Encounter date"])?.format("YYYYMMDD") <= moment(data?.toDate)?.format("YYYYMMDD") ||
          moment(e?.["Discharge date"])?.format("YYYYMMDD") <= moment(data?.toDate)?.format("YYYYMMDD")
      );
    if (returningValue(data?.diagnosis, "")) res = res?.filter((e) => e?.["Diagnosis.x"] === data?.diagnosis);
    if (returningValue(data?.visitType, "")) res = res?.filter((e) => e?.["Encounter Type"] === data?.visitType);

    res = res?.map((e) => ({
      ...e,
      date: moment(e?.["Encounter date"])?.format("YYYY-MM-DD"),
    }));
    setTypes(res?.map((e) => e?.["Encounter Type"]));
    setDates(res?.map((e) => e?.date));
    setFilValues(res);

    if (data?.idNumber) {
      const idDetails = values.find((v) => v["ID Number"] === parseInt(data?.idNumber));
      setSelectedIdDetails(idDetails);
    }
  };

  useEffect(() => {
    settingTraces();
  }, [filValues, data]);

  useEffect(() => {
    getAllData();
  }, []);

  // Create a persistent color map for all encounter types from the initial data
  const uniqueTypes = [...new Set(values.map((e) => e["Encounter Type"]))];

   

  const colors = ["#927BE7", "#1ec198", "#F6713C", "#E04AD6","#3D657E"];

const colorMap = uniqueTypes.reduce((acc, type, index) => {
  acc[type] = colors[index % colors.length]; // Cycle through colors if more types than colors
  return acc;
}, {});

  const traces = uniqueTypes.map((type) => {
    const filteredRecords = filValues.filter((e) => e["Encounter Type"] === type);
    const markerData = {
      x: [],
      y: [],
      mode: "markers",
      marker: {
        symbol: "circle",
        color: colorMap[type] || "gray",
        size: 20,
      },
      hoverinfo: "text",
      text: [],
      name: type,
    };

    filteredRecords.forEach((record) => {
      const encounterDate = moment(record["Encounter date"]);
      const dischargeDate = moment(record["Discharge date"]);
      const duration = dischargeDate.diff(encounterDate, "days");

      for (let i = 0; i <= duration; i++) {
        const date = encounterDate.clone().add(i, "days").format("YYYY-MM-DD");
        markerData.x.push(date);
        markerData.y.push(type);
        markerData.text.push(
          `</br> Type: ${record["Encounter Type"]} </br> Diagnosis:${record?.["Diagnosis.x"]} </br> Date:${moment(
            encounterDate
          ).format("DD-MM-YYYY")} To ${moment(dischargeDate).format("DD-MM-YYYY")} </br> Cost:${
            record?.["Cost"]
          } </br> Days:${record?.["Days"]}`
        );
      }
    });

    return markerData;
  });

  const layout = {
    legend: {
      traceorder: "normal",
    },
    xaxis: {
      side: "top",
      showline: true,
      linecolor: "#E9E9E9",
      linewidth: 2,
      tickfont: {
        family: "Arial",
        size: 15,
        color: "black",
      },
    },
    yaxis: {
      visible: false,
    },
    shapes: [
      {
        type: "line",
        x0: moment().format("YYYY-MM-DD"),
        y0: 0,
        x1: moment().format("YYYY-MM-DD"),
        y1: 1,
        xref: "x",
        yref: "paper",
        line: {
          color: "red",
          width: 2,
          dash: "dash",
        },
      },
    ],
  };

  const getFiltersValues = (key) => {
    let res = values?.filter((v) => (data?.["idNumber"] > 0 ? data?.["idNumber"] == v?.["ID Number"] : true));
    if (key === "Diagnosis.x")
      res = res?.filter((v) =>
        returningValue(data?.["visitType"], "") ? v?.["Encounter Type"] === data?.["visitType"] : true
      );
    return getUniqueByKey(res, key);
  };

  useEffect(() => {
    addObject({ visitType: "", diagnosis: "" });
  }, [data?.idNumber]);

  return (
    <div className="">
      <Col className=" d-flex">
        <Col lg={2} style={{ height: "100vh", backgroundColor: "#F7F7F7" }} className="   ">
          <Col lg={12} className="mb-4">
            <img src={anatrx} width={100} height="auto" className="mt-3" />
          </Col>
          <Col lg={12} className=" p-3 text-start">
            <h4>Filter Data</h4>
            <hr />
          </Col>
          <Col lg={12} className=" p-3 text-start">
            <label>Select Id</label>
            <select
              name="idNumber"
              onChange={formChange("")}
              value={returningValue(data?.["idNumber"], "")}
              className="form-control "
            >
              <option value={""}>Select ID</option>
              {getUniqueByKey(values, "ID Number")?.map((e, i) => (
                <option key={i}>{e?.["ID Number"]}</option>
              ))}
            </select>
          </Col>
          <Col lg={12} className=" p-3 text-start">
            <label>Select Visit</label>

            <select
              name="visitType"
              onChange={formChange("")}
              value={returningValue(data?.["visitType"], "")}
              className="form-control "
            >
              <option value={""}>Select Visit</option>
              {getFiltersValues("Encounter Type")?.map((e, i) => (
                <option key={i}>{e?.["Encounter Type"]}</option>
              ))}
            </select>
          </Col>
          <Col lg={12} className=" p-3 text-start">
            <label>Select Diagnosis</label>
            <select
              name="diagnosis"
              onChange={formChange("")}
              value={returningValue(data?.["diagnosis"], "")}
              className="form-control "
            >
              <option value={""}>Select Diagnosis</option>
              {getFiltersValues("Diagnosis.x")?.map((e, i) => (
                <option key={i}>{e?.["Diagnosis.x"]}</option>
              ))}
            </select>{" "}
          </Col>
          <Col lg={12} className=" p-3 text-start">
            <label>From Date</label>
            <ReactDatePicker
              className={`form-control`}
              selected={returningValue(data?.["fromDate"], "Date")}
              minDate={new Date(1900, 1, 1)}
              onChange={(e) => {
                handleDateChange(e, "fromDate");
              }}
              autoComplete="off"
              name="fromDate"
              dateFormat="dd-MM-yyyy"
              placeholderText={"From Date"}
              popperClassName="react-datepicker-popper"
              showMonthDropdown
              showYearDropdown
              style={{ fontSize: "20px" }}
              dropdownMode="select"
            />{" "}
          </Col>
          <Col lg={12} className=" p-3 text-start">
            <label className=" ">To Date</label>

            <ReactDatePicker
              className={`form-control `}
              selected={returningValue(data?.["toDate"], "Date")}
              minDate={new Date(1900, 1, 1)}
              onChange={(e) => {
                handleDateChange(e, "toDate");
              }}
              autoComplete="off"
              name="toDate"
              dateFormat="dd-MM-yyyy"
              placeholderText={"To Date"}
              popperClassName="react-datepicker-popper"
              showMonthDropdown
              showYearDropdown
              style={{ fontSize: "20px" }}
              dropdownMode="select"
            />
          </Col>
        </Col>
        <Col lg={10} style={{ height: "100vh" }}>
          <Col lg={12} className="border p-3  d-flex justify-content-between align-items-center ">
            <Col lg={10} className="d-flex justify-content-around align-items-center ">
              <p className="fw-semibold">Patient wise dashboard</p>
              <p className="fw-semibold">Population health dashboard</p>
              <p className="fw-semibold">Region wise dashboard</p>
            </Col>

            <Col lg={2} className="d-flex justify-content-around align-items-center">
              <MdOutlineSettings size={25} />
              <GoBell size={25} />
              <img
                className="  img-fluid img-thumbnail border   hover-animate-image"
                src={anatrx}
                alt="p"
                style={{ height: "40px", width: "40px", borderRadius: "100%" }}
                onClick={() => navigate("/")}
              />{" "}
            </Col>
          </Col>
          <Col lg={12}>
            <Plot style={{ height: "100%" }} className="" data={traces} layout={layout} />
            {uniqueTypes.map((type) => (
              <Button key={type} className="ms-2 border-0" style={{ backgroundColor: colorMap[type] || "gray" }}>
                {type} Cost:{" "}
                {filValues
                  .filter((e) => e["Encounter Type"] === type)
                  .reduce((acc, curr) => acc + parseFloat(curr.Cost), 0)}
              </Button>
            ))}
            <Button className="ms-2" style={{ backgroundColor: "#8590ad" }}>
              Total Cost : {filValues?.reduce((a, c) => a + c?.["Cost"], 0).toFixed(2)}
            </Button>
          </Col>

         {/* {selectedIdDetails&&  <hr/>}
          {selectedIdDetails && (
            <div className="mt-3 d-flex j ">
             
              <div className="col-md-4 d-flex justify-content-center ">
                <div>
                <div className="col-md-12 d-flex   ">
                  <div className="text-start     fw-semibold">Age :</div>
                  <div className="text-center  ">{selectedIdDetails["Age"]}</div>
                </div>
                <div className="col-md-12 d-flex   ">
                  <div className="text-start     fw-semibold">Race :</div>
                  <div className="text-center  ">{selectedIdDetails["Race"]}</div>
                </div>
                <div className="col-md-12 d-flex   ">
                  <div className="text-start     fw-semibold">Sex :</div>
                  <div className="text-center  ">{selectedIdDetails["Sex"]}</div>
                </div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center ">
                <div>
                <div className="col-md-12 d-flex   ">
                  <div className="text-start    fw-semibold">Number of Office Visits :</div>
                  <div className="text-center  ">{selectedIdDetails["Number of Office Visits"]}</div>
                </div>
                <div className="col-md-12 d-flex   ">
                  <div className="text-start c    fw-semibold">Number of Medications :</div>
                  <div className="text-center  ">{selectedIdDetails["Number of Medications"]}</div>
                </div>
                <div className="col-md-12 d-flex   ">
                  <div className="text-start     fw-semibold">Duration of Medication (days) :</div>
                  <div className="text-center  ">{selectedIdDetails["Duration of Medication (days)"]}</div>
                </div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center ">
                <div>              <div className="col-md-12 d-flex   ">
                  <div className="text-start     fw-semibold">Medication Adherence :</div>
                  <div className="text-center  ">{selectedIdDetails["Medication Adherence"]}</div>
                </div>
                <div className="col-md-12 d-flex   ">
                  <label className="text-start     fw-semibold">Medications :</label>
                  <div className="text-center  ">{selectedIdDetails["Medications"]}</div>
                </div>
                </div>

              </div>
            </div>
          )} */}
        </Col>
      </Col>
    </div>
  );
};

export default HomeNewDatesRange;
